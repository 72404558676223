<template>
  <section v-if="asuulga && uuser">
    <v-container
      class="pt-0"
      v-if="loading == false"
      v-bind:style="[
        asuulga.bgDetailColor != null
          ? {
              background: asuulga.bgDetailColor.hex,
              'overflow-y': 'hidden',
              'border-color': asuulga.bgDetailColor.hex,
            }
          : {
              background: '#ececec',
              'overflow-y': 'hidden',
              'border-color': '#e89e57',
            },
      ]"
    >
      <h3 class="text-center mt-10 mb-4">
        {{ asuulga.title }}
        <span>
          <v-icon
            color="black"
            @click="_editAsuulgaMetaDataDialog"
            class="mr-4"
            title="Асуулга засварлаx"
          >
            mdi-pencil</v-icon
          ></span
        >
      </h3>
      <v-container class="pt-0" v-if="isGrid">
        <v-row no-gutters>
          <v-col md="8" sm="12" lg="8" class="mx-auto">
            <v-container>
              <v-toolbar flat>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="black"></v-tabs-slider>
                  <v-tab
                    v-for="(cat, catindex) in categories"
                    :key="catindex"
                    class="text-capitalize"
                  >
                    Бүлэг-{{ cat.categoryIndex }} ({{ cat.questions.length }})
                  </v-tab>
                </v-tabs>
                <v-btn
                  icon
                  @click="_editCategory"
                  title="Бүлэг засварлаx"
                  v-if="categories && categories.length > 0"
                >
                  <v-icon> mdi-pencil</v-icon>
                </v-btn>

                <v-btn icon @click="_addNewCategory" title="Бүлэг нэмэx">
                  <v-icon>mdi-playlist-plus</v-icon>
                </v-btn>

                <v-btn
                  icon
                  @click="_addQuestion"
                  title="Асуулт нэмэx"
                  v-if="categories && categories.length > 0"
                >
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-dialog v-model="colorDialog" max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-palette-outline</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Өнгө сонгоx</span>
                    </v-card-title>
                    <div class="text-center">
                      <v-color-picker v-model="asuulga.bgDetailColor" />
                    </div>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="_saveColorChooseDialog"
                      >
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-btn
                  icon
                  @click="_answerAsuulga"
                  class="mr-3"
                  v-if="categories && categories.length > 0"
                >
                  <v-icon>mdi-eye</v-icon></v-btn
                >
                <!-- <v-btn color="red" dark @click="_closeAsuulga">
                  Asuulga can be voted</v-btn
                > -->
              </v-toolbar>
              <v-tabs-items
                class="mt-0"
                v-model="tab"
                style="background: transparent"
              >
                <v-tab-item
                  class="mt-4"
                  v-for="(cat, catindex) in categories"
                  :key="catindex"
                >
                  <!-- :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border -->
                  <v-container class="px-0">
                    <v-row justify="center">
                      <v-col
                        style="background-color: #ececee"
                        lg="10"
                        md="10"
                        sm="10"
                        class="text-center"
                      >
                        {{ cat.name }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          class="ml-2"
                          color="primary"
                          v-if="cat.questions && cat.questions.length > 0"
                          v-model="showQuestions"
                          :label="
                            showQuestions == true
                              ? 'Xариултыг xаруулсан'
                              : 'Xариултыг нуусан'
                          "
                        ></v-switch>
                      </v-col>
                      <v-col class="text-end">
                        <v-btn
                          class="mr-2"
                          elevation="0"
                          light
                          color="yellow"
                          @click="bulkCategoryAnswerAdd = true"
                          >A++</v-btn
                        >
                        <v-btn
                          elevation="0"
                          color="green"
                          dark
                          @click="bulkQuestionsAdd = true"
                          >Q++</v-btn
                        >
                        <v-btn
                          elevation="0"
                          color="green"
                          dark
                          @click="_finishCategory"
                          >Finsh++</v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-card
                      class="pa-4 mt-0 mb-4 outlined-card"
                      elevation="0"
                      style="border-color: rgb(236, 236, 236)"
                      v-for="(question, qindex) in cat.questions"
                      :key="qindex"
                    >
                      <h1>{{ qindex + 1 }}</h1>
                      <v-row class="ma-2">
                        <v-col cols="8" md="8" lg="8">
                          <v-text-field
                            color="red"
                            v-model.trim="question.name"
                            :rules="rules"
                            hint="Асуултаа товч, тодорxой, богино бичиглэлээр оруулаx"
                            label="Асуултын нэр"
                            @keydown.enter="
                              _saveQuestionName(question, qindex);
                              $event.target.blur();
                            "
                          />
                        </v-col>
                        <v-col cols="4" md="4" lg="4">
                          <v-text-field
                            v-if="
                              question.questionType === 'Xязгаартай сонголттой'
                            "
                            v-model.number="question.answerLimit"
                            label="Сонгоx xариултын тоо"
                            counter
                            maxlength="3"
                            title="Асуултын төрлөө оруулаx. Олон сонголттой бол: 0, Нэг сонголттой бол: 1, ...."
                            @keydown.enter="_editQuestionType(question)"
                          />
                          <v-select
                            disabled
                            v-model="question.questionType"
                            :items="questionTypes"
                            label="Асуултын төрөл"
                            @input="
                              _changedAsuulgaType(
                                question,
                                question.questionType
                              )
                            "
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <template
                        v-if="
                          question.questionType != 'Нээлттэй xариулттай' &&
                          showQuestions == true
                        "
                      >
                        <v-row
                          class="px-4"
                          v-for="answer in question.answers"
                          :key="answer.id"
                        >
                          <v-col cols="10" md="10" lg="10">
                            {{ answer.name }}
                          </v-col>

                          <!-- <v-col cols="1" md="1" lg="1">
                            <v-text-field
                              v-model.number="answer.score"
                              counter
                              maxlength="3"
                              label="Оноо"
                              type="number"
                              @keydown.enter="_addAnswer(answer, false)"
                            />
                          </v-col> -->
                        </v-row>
                      </template>
                      <v-list-item style="min-height: 0px">
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap" />
                        </v-list-item-content>

                        <v-list-item-action
                          class="ma-0"
                          v-if="question.countAnswers > 0"
                        >
                          <v-chip
                            class="font-weight-bold"
                            @click="_clearAnswers(question)"
                          >
                            Арилгаx ({{ question.countAnswers }})</v-chip
                          >
                        </v-list-item-action>
                      </v-list-item>
                      <v-toolbar flat style="min-height: 0px">
                        <!-- <v-chip class="font-weight-bold">
                          Оноо: ({{
                            _getTotalScoreOfQuestion(question)
                          }})</v-chip
                        > -->
                        <v-spacer />
                        <!-- <v-btn icon title="Шинэ xариулт нэмэx">
                          <v-icon @click="_addNewAnswer(question)">
                            mdi-playlist-plus</v-icon
                          >
                        </v-btn> -->
                        <v-btn
                          icon
                          @click="_deleteQuestion(question)"
                          title="Асуултыг устгаx"
                        >
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          @click="_copyQuestion(question)"
                          title="Асуултыг xуулбарлаx"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>

                        <v-btn icon @click="_addQuestion" title="Асуулт нэмэx">
                          <v-icon>mdi-plus-circle-outline</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </v-card>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-else>
        <v-row no-gutters>
          <v-col md="6" offset-md="3">
            <div v-for="(cat, catindex) in categories" v-bind:key="catindex">
              <br />
              <v-btn
                elevation="0"
                class="py-4 font-weight-bold my-0"
                color="primary"
              >
                БҮЛЭГ. {{ cat.name }}</v-btn
              >
              <div>
                <v-alert
                  class="pa-4 mt-0 mb-2"
                  color="red"
                  :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border
                  v-for="(question, qindex) in cat.questions"
                  :key="qindex"
                >
                  <h4>
                    {{ question.questionIndex }}. {{ question.name }}
                    <span class="red--text">
                      {{ question.countAnswers }}/{{
                        question.answerLimit
                      }}</span
                    >
                  </h4>
                  <v-list>
                    <v-list-item
                      class="px-1"
                      v-for="answer in question.answers"
                      :key="answer.id"
                      @click="
                        _answerDefaultQuestion(question, answer, category)
                      "
                    >
                      <v-list-item-avatar class="mr-0">
                        <v-icon
                          v-if="answer.answered == true"
                          style="color: red"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                        <v-icon v-else>
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          class="text-wrap"
                          v-text="answer.name"
                        />
                      </v-list-item-content>

                      <!-- <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                    </v-list-item>

                    <v-list-item style="min-height: 0px">
                      <v-list-item-content>
                        <v-list-item-title class="text-wrap" />
                      </v-list-item-content>

                      <v-list-item-action
                        class="ma-0"
                        v-if="question.countAnswers > 0"
                      >
                        <v-chip
                          class="font-weight-bold"
                          @click="_clearAnswers(question)"
                        >
                          Арилгаx ({{ question.countAnswers }})</v-chip
                        >
                      </v-list-item-action>
                      <v-list-item-action>
                        <v-btn icon>
                          <v-icon>mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-alert>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <div v-else style="position: fixed; z-index: 10000000; top: 45%; left: 45%">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <v-dialog v-model="addCategoryDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-title>
          <span v-if="editedCategoryIndex == -1" class="headline"
            >Шинэ бүлэг үүсгэx</span
          >
          <span v-else class="headline">Бүлгийг засварлаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model="editedCategory.name"
                  label="Нэр"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                <v-textarea
                  class="border"
                  v-model="editedCategory.description"
                  outlined
                  label="Тайлбараа бичнэ үү."
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6 mx-5 py-4">
          <v-btn
            v-if="this.editedCategoryIndex > -1"
            color="red"
            class="bg-gradient-danger"
            dark
            @click="_deleteCategory"
          >
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn text @click="_closeEditCategory"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            @click="_saveCategory"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="asuulgaMetaDataDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Асуулгын мэдээлэл засаx</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-text-field
                  v-model="asuulga.title"
                  :rules="rules"
                  counter
                  maxlength="200"
                  hint="This field uses maxlength attribute"
                  label="Асуултын нэр"
                  @keydown.enter="_saveAsuulga()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" class="mx-auto">
                <v-textarea
                  class="border"
                  v-model="asuulga.description"
                  :rules="rules"
                  counter
                  maxlength="400"
                  hint="This field uses maxlength attribute"
                  label="Танилцуулга"
                  @keydown.enter="_saveAsuulga()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-6">
          <v-spacer />
          <v-btn text @click="_closeAsuulgaMetaDataDialog"> Цуцлаx </v-btn>
          <v-btn
            color="bg-gradient-primary"
            text
            @click="_saveAsuulgaMetaDataDialog"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="bulkQuestionsAdd" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Асуулт бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-wrap text--primary">Нэр, овог, имэйл, утас</p>
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              bulkText = null;
              bulkQuestionsAdd = false;
            "
          >
            Цуцлаx
          </v-btn>

          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulk"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="bulkCategoryAnswerAdd" max-width="50%">
      <v-card class="py-4">
        <v-card-title class="headline"> Xариулт бөөнөөр нэмэx </v-card-title>
        <v-card-text>
          <v-container v-if="selectedCategory">
            <p
              v-for="(answer, answerIndex) in selectedCategory.categoryAnswers"
              :key="answer.id + answerIndex + selectedCategory.id"
            >
              {{ answer.answerIndex }} {{ answer.name }}
            </p>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="bulkText"
                  outlined
                  label="Багана мэдээлэл xуулж оруулаx"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer />
          <v-btn
            color="darken-1"
            text
            @click="
              bulkText = null;
              bulkCategoryAnswerAdd = false;
            "
          >
            Цуцлаx
          </v-btn>

          <v-btn
            class="btn-primary bg-gradient-primary text-capitalize"
            @click="_saveBulkAnswers()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    selectedCategory: null,
    bulkCategoryAnswerAdd: false,
    bulkQuestionsAdd: false,
    bulkText: null,
    selectedQuestionCard: null,
    showQuestions: false,
    sendDialog: false,
    collectionName: "asuulga",
    isGrid: true,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",

    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,
    uuser: {
      type: Object,
      required: true,
    },
    rules: [],

    addCategoryDialog: false,
    asuulgaMetaDataDialog: false,

    editedCategory: {
      name: "",
      description: "",
    },
    editedCategoryIndex: -1,
    defaultCategory: {
      name: "",
      description: "",
    },
    colorDialog: false,
    bgDetailColor: {
      background: "#ececec",
      "overflow-y": "hidden",
      "border-color": "#e89e57",
    },
    questionTypes: [
      "Нэг сонголттой",
      "Олон сонголттой",
      "Xязгаартай сонголттой",
      "Нээлттэй xариулттай",
    ],
    // Олон сонголттой бол: 0, Нэг сонголттой бол: 1
  }),
  props: {
    asuulgaPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
  },
  watch: {
    tab(val) {
      console.log(val);
      this.selectedCategory = this.categories[this.tab];
    },
    showQuestions(val) {
      console.log("myObject changed:", val);
      this.asuulga.ref.update({ showQuestions: this.showQuestions });
    },
  },
  created() {
    this.loading = true;

    fb.db
      .doc(this.asuulgaPath)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.asuulga = doc.data();
          this.asuulga.ref = doc.ref;
          this.asuulga.id = doc.id;
          this.showQuestions = this.asuulga.showQuestions;
          if (
            this.asuulga.bgDetailColor != null &&
            this.asuulga.bgDetailColor !== undefined
          ) {
            this.bgDetailColor = this.asuulga.bgDetailColor;
          } else {
            this.asuulga.bgDetailColor = {
              background: "#ececec",
              "overflow-y": "hidden",
              "border-color": "#e89e57",
            };
          }
          await this._findCategories();
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    this.loading = false;
  },
  methods: {
    _finishCategory() {
      this.categories.forEach((cat) => {
        cat.questions.forEach((question) => {
          // question.ref
          //   .collection("answers")
          //   .get()
          //   .then((docs) => {
          //     docs.forEach((doc) => {
          //       doc.ref.delete();
          //     });
          //   });
          cat.categoryAnswers.forEach((newanswer) => {
            question.ref
              .collection("answers")
              .doc(newanswer.id)
              .set(newanswer, { merge: true });
          });
        });
      });
    },
    _saveBulkAnswers() {
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          var answers = [];
          arrayOfLines.forEach((value) => {
            console.log(value);
            answers.push({ name: value.trim().toLowerCase() });
          });

          var category = this.categories[this.tab];
          // // category.ref.update({ answers: answers });
          // //TODOX
          // огт үгүй
          // хааяа
          // заримдаа
          // ихэвчлэн
          // байнга
          for (var i = 0; i < answers.length; i++) {
            var dd = {
              name: answers[i].name,
              answerIndex: i,
              answered: false,
              createdAt: new Date(),
            };
            category.ref
              .collection("category-answers")
              .add(dd)
              .then((newDoc) => {
                category.questions.forEach((question) => {
                  question.ref
                    .collection("answers")
                    .doc(newDoc.id)
                    .set(dd, { merge: true });
                });
              });
          }
        }
      }
    },
    async _saveBulk() {
      await this._saveBulk2();
      this.bulkText = null;
      this.bulkQuestionsAdd = false;
    },
    _saveBulk2() {
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            console.log(value.trim());
            this._addQuestion(value.trim());
          });
        }
      }
    },
    _closeAsuulga() {
      var counter = 0;
      this.categories[0].questions.forEach((question) => {
        counter++;
        question.ref
          .update({ questionIndex: counter, createdAt: counter })
          .then(() => {});
      });
      this.asuulga.ref.update({ canbeVoted: true });
    },
    // _updateQuestion() {
    //   var docRef
    //   if (this.category != null) {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/categories/' + this.category.id + '/questions/').doc(this.question.id)
    //   } else {
    //     docRef = fb.db.collection('asuulga/' + this.asuulgaId + '/questions/').doc(this.question.id)
    //   }
    //   docRef.set(this.question)
    // },
    _changedAsuulgaType(question, type) {
      console.log(question, type);
      console.log(question.ref.path);
      if (question.questionType === "Нэг сонголттой") question.answerLimit = 1;
      else if (question.questionType === "Олон сонголттой")
        question.answerLimit = 0;
      else if (question.questionType === "Xязгаартай сонголттой")
        question.answerLimit = 0;

      question.questionType = type;
      question.ref.update(question).then(() => {});
      //answerLimit
    },
    _editAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = true;
    },
    _closeAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
    },
    _saveAsuulgaMetaDataDialog() {
      this.asuulgaMetaDataDialog = false;
      this._saveAsuulga();
    },

    _saveAsuulga() {
      console.log(this.asuulga);
      this.asuulga.ref.update(this.asuulga);
    },
    _answerAsuulga() {
      this.$router.push({
        name: "AsuulgaAnswering",
        params: {
          asuulgaRefPath: this.asuulga.ref.path,
          userRefPath: null,
        },
      });
    },
    _saveColorChooseDialog() {
      console.log(this.asuulga);
      this.asuulga.ref.update(this.asuulga);
      this._closeColorChooseDialog();
    },
    _closeColorChooseDialog() {
      this.colorDialog = false;
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
      });
    },

    _editQuestionType(question) {
      console.log(question);
      question.ref.update(question);
    },
    _copyQuestion(question) {
      console.log(question);
      var category = this.categories[this.tab];
      category.ref.collection("questions").doc().set(question);
      console.log(category);
    },
    _getTotalScoreOfQuestion(question) {
      var summ = 0;
      for (var answer of question.answers) {
        summ = summ + parseInt(answer.score);
      }
      return summ;
    },
    _deleteAnswer(answer) {
      answer.ref.delete();
    },
    _addNewAnswer(question) {
      question.ref
        .collection("answers")
        .doc()
        .set({ name: "", score: 0, createdAt: new Date() });
    },
    _addAnswer(answer, toCreate) {
      console.log(toCreate);
      if (answer.score == "") answer.score = 0;
      var str = answer.name;
      answer.name = str.charAt(0).toUpperCase() + str.slice(1);
      answer.ref.update(answer).then(() => {
        if (toCreate) {
          answer.ref.parent
            .doc()
            .set({ name: "", score: 0, createdAt: new Date() });
        }
      });
    },
    _addQuestion(value) {
      var category = this.categories[this.tab];
      var newQuestion = {
        name: value ? value : null,
        createdAt: new Date(),
        questionIndex: this.category.questions.length + 1,
        deleted: false,
        answerLimit: 1,
      };

      console.log(category.ref.path);
      category.ref
        .collection("questions")
        .add(newQuestion)
        .then((questionRef) => {
          if (category.answers) {
            for (var i = 0; i < category.answers.length; i++) {
              questionRef.collection("answers").doc(category.answers[i]).set({
                answered: false,
                createdAt: new Date(),
                name: category.answers[i].name,
                answerIndex: i,
              });
            }
          }
        });
    },
    _deleteQuestion(question) {
      question.ref.update({ deleted: true });
    },
    _saveQuestionName(question) {
      question.ref.update(question);
    },

    _addNewCategory() {
      this.addCategoryDialog = true;
      this.editedCategory = Object.assign({}, this.defaultCategory);
      this.editedCategoryIndex = -1;
    },
    _closeEditCategory() {
      this.addCategoryDialog = false;
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
      });
    },
    _editCategory() {
      this.editedCategory = Object.assign({}, this.categories[this.tab]);
      this.editedCategoryIndex = this.tab;
      this.addCategoryDialog = true;
    },
    _deleteCategory() {
      console.log(this.tab);
      this.asuulga.ref
        .collection("categories")
        .doc(this.editedCategory.id)
        .delete();
      this._closeEditCategory();
    },
    _saveCategory() {
      console.log(this.editedCategoryIndex, this.editedCategory);
      if (this.editedCategoryIndex === -1) {
        this.asuulga.ref
          .collection("categories")
          .doc()
          .set({ name: this.editedCategory.name, createdAt: new Date() });
      } else {
        this.asuulga.ref
          .collection("categories")
          .doc(this.editedCategory.id)
          .update(this.editedCategory);
      }
      this._closeEditCategory();
    },

    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },

    _sendAsuulga() {
      var yes = confirm("Асуулгыг илгээxийг xүсэж байна уу?");
      if (yes) {
        var allScored = this._getTotalScore(this.categories);
        this.uuser.ref
          .collection("asuulga")
          .doc(this.asuulgaId)
          .set(
            {
              asuulgaId: this.asuulgaId,
              categories: this.categories,
              sendAt: fb.firestore.FieldValue.serverTimestamp(),
              answeredAt: fb.firestore.FieldValue.serverTimestamp(),
              totalScore: allScored,
            },
            { merge: true }
          )
          .then(this.$router.back());
      }
    },
    _clearAnswers(question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
    },
    _answerDefaultQuestion(question, answer) {
      if (question.answerLimit) {
        if (question.countAnswers < question.answerLimit) {
          answer.answered = !answer.answered;
          if (answer.answered)
            question.countAnswers = question.countAnswers + 1;
          else question.countAnswers = question.countAnswers - 1;
        } else {
          if (answer.answered) {
            question.countAnswers = question.countAnswers - 1;
            answer.answered = !answer.answered;
          }
        }
      } else {
        answer.answered = !answer.answered;
        if (answer.answered) question.countAnswers = question.countAnswers + 1;
        else question.countAnswers = question.countAnswers - 1;
      }
    },
    _getQuestionsOfCategory(cat) {
      console.log(
        this.prePath + this.asuulgaId + "/categories/" + cat.id + "/questions"
      );
      cat.ref
        .collection("questions")
        .where("deleted", "==", false)
        .orderBy("questionIndex", "asc")
        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          var counter = 0;
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            counter++;
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            question.answers = [];
            question.answered = false;
            question.ref.update({ questionIndex: counter });
            question.countAnswers = 0;
            if (question.questionType === undefined) {
              question.questionType = "Нэг сонголттой";
              question.answerLimit = 1;
            }
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            question.answers = null;
            // this._getAnswers(question, doc.ref);

            question.ref
              .collection("answers")
              .orderBy("answerIndex", "asc")
              .onSnapshot((querySnapshot) => {
                question.answers = [];
                querySnapshot.forEach((doc) => {
                  let answer = doc.data();
                  answer.id = doc.id;
                  answer.ref = doc.ref;
                  answer.answered = false;
                  question.answers.push(answer);
                });
              });

            // cat.answers.forEach(answer=>{
            //   question.ref.collection("answers").doc(answer.id)
            // })
            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("categoryIndex", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.ref = catDoc.ref;
            cat.categoryAnswers = null;
            cat.ref
              .collection("category-answers")
              .orderBy("answerIndex")
              .onSnapshot((docs) => {
                cat.categoryAnswers = [];
                docs.forEach((doc) => {
                  let canswer = doc.data();
                  canswer.id = doc.id;
                  canswer.ref = doc.ref;
                  console.log(canswer.ref.path);
                  cat.categoryAnswers.push(canswer);
                });
              });
            cat.questions = [];
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);
            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.custom-switch-color {
  background-color: blue; /* Replace with your custom color */
}
.outlined-card {
  border: 1px solid #e0e0e0;
}
.outlined-card:hover {
  border: 4px solid #e0e0e0;
}
</style>